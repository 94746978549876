













import { Component, Vue } from 'vue-property-decorator';
import HomeIcon from 'vue-material-design-icons/Home.vue';

@Component({ components: { HomeIcon } })
export default class NotFound extends Vue {
  mobileBreakPoint: MediaQueryList = window.matchMedia('(max-width: 600px)');
  isScreenMobile: boolean = this.mobileBreakPoint.matches;

  mounted(): void {
    this.mobileBreakPoint.onchange = this.mediaQueryHandler;
  }

  mediaQueryHandler(): void {
    this.isScreenMobile = this.mobileBreakPoint.matches;
  }

  onClick(): void {
    this.$router.push('/');
  }
}
